import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

export type Language = "ar" | 'en';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  constructor(private translateService: TranslateService) {
  }

  setUpLanguages() {
    this.translateService.addLangs(['en', 'ar']);
    this.translateService.setDefaultLang('en');
  }

  translatePhrase(phrase: string, params?: object): string {
    return this.translateService.instant(phrase, params);
  }

  switchLanguages(lang: Language) {
    this.translateService.use(lang);
  }

  getCurrentLang(): string {
    return this.translateService.currentLang;
  }

  onLangChange() {
    return this.translateService.onLangChange;
  }
}
